import * as React from "react";
import { Helmet } from "react-helmet";
import { meta_title } from "../components/Meta";
import ShopTicker from "../components/svg/ShopTicker";
import gsap from "gsap";
import { useEffect, useRef } from "react";
import productData from "../data/products.json";
import ShopCard from "../components/ShopCard/ShopCard";
import Ticker from "../components/Ticker/Ticker";
import TickerGroup from "../components/Ticker/TickerGroup";

const ShopPage = () => {
  //   gsap.registerPlugin(ScrollTrigger);
  //   const heroVideo = useRef(null);

  //   useEffect(() => {
  //     ScrollTrigger.create({
  //       trigger: heroVideo.current,
  //       start: "top top",
  //       pin: true,
  //       pinSpacing: false,
  //     });

  //     setTimeout(() => {
  //       ScrollTrigger.refresh();
  //     }, 10);
  //   }, []);

  return (
    <>
      {/* @ts-ignore */}
      <Helmet>
        <title>Shop | {meta_title}</title>
        <meta
          property="og:image"
          content={"https://gojiralabs.com/favicon/meta-shop.jpg"}
        />
        <meta property="og:url" content="https://gojiralabs.com/shop/" />
        <meta property="twitter:url" content="https://gojiralabs.com/shop/" />
      </Helmet>
      <div>
        <h1 className={`sr-only`}>Shop</h1>
        <img
          src={"/images/shop/shop-hero.jpg"}
          alt={`shop`}
          className={"w-full h-auto block mx-auto max-w-[1000px] py-14"}
        />
      </div>
      <Ticker
        direction={"ltr"}
        className={`bg-red-light py-3 md:py-5`}
        duration={36.666666666666667}
        minHeight={"40px"}
      >
        <ShopTicker color={"#ffffff"} />
      </Ticker>

      {/* Mobile */}
      <div
        className={`grid md:hidden grid-cols-2 gap-1 md:gap-2 lg:grid-cols-3`}
      >
        {productData.map((product, index) => {
          return (
            <ShopCard
              name={product.name}
              price={product.price}
              image={product.image}
              key={index}
              style={
                index === productData.length - 1
                  ? { gridColumn: "1 / -1" }
                  : null
              }
              imageMaxWidth={
                index === productData.length - 1 ? "160px" : undefined
              }
            />
          );
        })}
      </div>

      {/* Desktop */}
      <div
        className={`hidden md:grid grid-cols-2 gap-1 md:gap-2 lg:grid-cols-3`}
      >
        {productData.map((product, index) => {
          return (
            <ShopCard
              name={product.name}
              price={product.price}
              image={product.image}
              key={index}
            />
          );
        })}
      </div>
      <TickerGroup />
    </>
  );
};

export default ShopPage;
