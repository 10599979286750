import React from "react";
import Ticker from "./Ticker";

const tickerData = [
  {
    text: "100% not from alibaba. 100% not from alibaba. 100% not from alibaba.",
    direction: "rtl" as "ltr" | "rtl" | undefined,
    duration: 36.666666666666667,
  },
  {
    text: "“I greatly value the compressed air”   ·   “finally, a luxury game controller I can barely lift”   ·   “best night’s sleep I’ve ever had”   ·",
    direction: "ltr" as "ltr" | "rtl" | undefined,
    duration: 54,
  },
  {
    text: "possessions are the only reason to live. possessions are the only reason to live. possessions are the only reason to live.",
    direction: "rtl" as "ltr" | "rtl" | undefined,
    duration: 36.666666666666667,
  },
  {
    text: "“6 feet of pure extension”   ·   “I don’t always listen to tapes but when I do”   ·   “I dropped the building blocks on my toe”   ·",
    direction: "ltr" as "ltr" | "rtl" | undefined,
    duration: 51.333333333333333,
  },
  {
    text: "get it before it’s gone. get it before it’s gone. get it before it’s gone.",
    direction: "rtl" as "ltr" | "rtl" | undefined,
    duration: 23.333333333333333,
  },
];

const TickerGroup = () => {
  return (
    <>
      {/* Desktop */}
      <div className="mt-28 hidden md:block">
        {tickerData.map((item, index) => {
          // function that retuns true if the index is even
          const isEven = (index: number) => index % 2 === 0;
          return (
            <Ticker
              direction={item.direction}
              className={`bg-black 
			${isEven(index) ? "py-2" : "py-7"}
			 ${index === 0 ? "border-t" : null} border-b border-red-light`}
              duration={item.duration}
              minHeight="85px"
              isSticky={false}
              key={index}
            >
              <p
                className={`text-red-light uppercase whitespace-nowrap font-base ${
                  isEven(index) ? "md:text-[50px]" : "md:text-[22px]"
                } font-mdio`}
              >
                {item.text}&nbsp;
              </p>
            </Ticker>
          );
        })}
      </div>

      {/* Mobile */}
      <div className="mt-20 block md:hidden">
        {tickerData.slice(0, 3).map((item, index) => {
          return (
            <Ticker
              direction={item.direction}
              className={`bg-black py-7
			 ${index === 0 ? "border-t" : null} border-b border-red-light`}
              duration={item.duration}
              minHeight="85px"
              isSticky={false}
              key={index}
            >
              <p
                className={`text-red-light uppercase whitespace-nowrap font-base font-mdio`}
              >
                {item.text}&nbsp;
              </p>
            </Ticker>
          );
        })}
      </div>
    </>
  );
};

export default TickerGroup;
